import axios, { AxiosRequestConfig } from 'axios'

import { getCookie } from 'libraries/cookie'

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'

    const token = getCookie('token')

    config.headers = {
      Authorization: `Bearer ${token}`,
      'x-custom-lang': selectedLanguage,
    }

    return config
  },

  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  config => Promise.resolve(config),
  async error => {
    return Promise.reject(error)
  }
)

export default axiosInstance

import { FC, useState, useRef } from 'react'
import classNames from 'classnames'

import SidebarMenu from './SidebarMenu'
import SidebarFooter from './SidebarFooter'
import SidebarSwitch from './SidebarSwitch'
import useOutsideClick from 'hooks/useOnClickOutside'

import { sidebarMenuItemsUtils } from './utils'
import styles from './Sidebar.module.scss'

const Sidebar: FC = () => {
  const ref = useRef(null)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleSidebarClose = () => {
    setIsSidebarOpen(false)
  }

  useOutsideClick(ref, handleSidebarClose)

  const sidebarWrapperClassName = classNames(styles.wrapper, {
    [styles.wrapper__closed]: !isSidebarOpen,
  })

  return (
    <nav ref={ref} className={sidebarWrapperClassName}>
      <SidebarMenu setOpen={toggleSidebar} open={isSidebarOpen} sidebarMenuItems={sidebarMenuItemsUtils} />
      <SidebarFooter open={isSidebarOpen} />
      <SidebarSwitch open={isSidebarOpen} setOpen={toggleSidebar} />
    </nav>
  )
}

export default Sidebar

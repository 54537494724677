import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'

export const gatNews = createAsyncThunk('admin/new', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin/news')

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const gatNewsById = createAsyncThunk('admin/getNews', async ({ id }: { id: number }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin/news')
    console.log(1, data)
    const activeNews = data.data.find((news: any) => news.id === id)
    console.log(activeNews, data, 'body')

    return activeNews
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const createNews = createAsyncThunk('news/create', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/admin/news', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const updateNews = createAsyncThunk('news/upate', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.put('/api/admin/news', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const deleteNews = createAsyncThunk('news/delete', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.delete(`/admin/news/${id}`)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

// Blog

export const getBlog = createAsyncThunk('blog/get', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin/blog')

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const gatBlogById = createAsyncThunk('admin/getBlog', async ({ id }: { id: number }, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin/blog')
    console.log(1, data)
    const activeNews = data.data.find((news: any) => news.id === id)
    console.log(activeNews, data, 'body')

    return activeNews
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const createBlog = createAsyncThunk('blog/create', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/admin/blog', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const updateBlog = createAsyncThunk('blog/upate', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.put('/admin/blog', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const deleteBlog = createAsyncThunk('blog/delete', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.delete(`/admin/blog/${id}`)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

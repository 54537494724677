import { createAsyncThunk } from '@reduxjs/toolkit'
import { isArray } from 'lodash'

import axiosInstance from 'libraries/axios'
import { setCookie } from 'libraries/cookie'
import { showNotification, ToastVersions } from 'libraries/toastify'

// import { COOKIE_EXPIRATION_TIME, REFRESH_COOKIE_EXPIRATION_TIME } from 'utils'

export const signIn = createAsyncThunk('auth/login', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/auth/login', body)

    console.log(data, 'data')

    // const rememberMe = getCookie('rememberMe') === '1' ?? '0'

    setCookie('token', data.data.accessToken)
    setCookie('user', JSON.stringify(data.data.user))
    setCookie('refreshToken', data.data.refreshToken)

    showNotification(ToastVersions.success, data.message)

    return data.user
  } catch (error: any) {
    if (isArray(error.response.data.message)) {
      const message = error.response.data.message[0].email[0]

      showNotification(ToastVersions.error, message)
    } else {
      showNotification(ToastVersions.error, error.response.data.message)
    }

    return rejectWithValue(error.response.data)
  }
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/auth-worker/forgot-password', body)

    showNotification(ToastVersions.success, data.message)

    return body
  } catch (error: any) {
    if (isArray(error.response.data.message)) {
      const message = error.response.data.message[0].email[0]

      showNotification(ToastVersions.error, message)
    } else {
      showNotification(ToastVersions.error, error.response.data.message)
    }

    return rejectWithValue(error.response.data)
  }
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.patch('/auth-worker/reset-password', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

export const inviteUser = createAsyncThunk('auth/inviteUser', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/auth-worker/invite-user', body)

    showNotification(ToastVersions.success, data.message)

    return null
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})

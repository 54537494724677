import { createSlice } from '@reduxjs/toolkit'

import { gatNews, gatNewsById, getBlog, gatBlogById } from './actions'

export const initialState: any = {
  data: [],
  activeNews: {},
  error: null,
  loading: false,
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    resetNewsState: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(gatNews.pending, state => {
      state.loading = true
      state.error = null
    })

    builder.addCase(gatNews.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.data = payload.data
    })

    builder.addCase(gatNews.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as null
    })

    builder.addCase(getBlog.pending, state => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getBlog.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.data = payload.data
    })

    builder.addCase(getBlog.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as null
    })

    builder.addCase(gatNewsById.pending, state => {
      state.loading = true
      state.error = null
    })

    builder.addCase(gatNewsById.fulfilled, (state, { payload }) => {
      console.log(payload, 'payload')
      state.loading = false
      state.error = null
      state.activeNews = payload
    })

    builder.addCase(gatNewsById.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as null
    })

    builder.addCase(gatBlogById.pending, state => {
      state.loading = true
      state.error = null
    })

    builder.addCase(gatBlogById.fulfilled, (state, { payload }) => {
      console.log(payload, 'payload')
      state.loading = false
      state.error = null
      state.activeNews = payload
    })

    builder.addCase(gatBlogById.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as null
    })
  },
})

export const { resetNewsState } = newsSlice.actions

const newsReducer = newsSlice.reducer

export default newsReducer

import { useLocation, useNavigate } from 'react-router-dom'

import { getCookie, removeCookie } from 'libraries/cookie'
import { useState, useEffect } from 'react'

const useAuthentication = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const token = getCookie('token') ?? false
    const bol = Boolean(token)

    setIsAuthenticated(bol)
  }, [location])

  const handleLogout = () => {
    navigate('/')
    removeCookie('user')
    removeCookie('token')
  }

  return { isAuthenticated, handleLogout, user: null }
}

export default useAuthentication

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import { Paper, Table, TableContainer, TableHead, TableRow } from '@mui/material'

import { Button } from 'components'
import { DeleteIcon, EditIcon } from 'assets'
import { EButtonVariants, ESizes } from 'types'

import styles from './NewsTable.module.scss'
import { StyledTableCell, StyledTableRow } from './NewsTable.styles'
import { NewsRequestState } from 'store/news/types'

interface INewsTableProps {
  deleteNews: (id: number) => void
  data: NewsRequestState[]
  isNewsPage: boolean
}

const NewsTable: FC<INewsTableProps> = ({ deleteNews, data, isNewsPage }) => {
  const navigate = useNavigate()

  const generateId = (id: number) => {
    if (id < 10) return `#00${id}`
    if (id < 100) return `#0${id}`

    return id
  }

  return (
    <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>id</StyledTableCell>
            <StyledTableCell>Thumbnail</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
            <StyledTableCell align='center'>Created at</StyledTableCell>
            <StyledTableCell align='right'>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, image, name, createdAt, coin }) => (
            <StyledTableRow
              key={id}
              onClick={() => {
                if (isNewsPage) {
                  navigate(`/news/update/${id}`)
                } else {
                  navigate(`/blog/update/${id}`)
                }
              }}
            >
              <StyledTableCell>{generateId(id)}</StyledTableCell>
              <StyledTableCell>
                <img src={image} alt='news' />
              </StyledTableCell>
              <StyledTableCell>{name}</StyledTableCell>
              <StyledTableCell>
                <div className={styles.coin}>
                  <img className={styles.coin__icon} src={coin.image} alt={coin.name} />
                  <p>{coin.name}</p>
                </div>
              </StyledTableCell>
              <StyledTableCell align='center'>{createdAt}</StyledTableCell>
              <StyledTableCell align='right'>
                <Button
                  size={ESizes.Small}
                  variant={EButtonVariants.Primary}
                  className={styles.wrapper__inner__button}
                  onClick={e => {
                    e.stopPropagation()
                    deleteNews(id)
                  }}
                >
                  <DeleteIcon />
                </Button>
                <Button size={ESizes.Small} variant={EButtonVariants.Primary} className={styles.wrapper__inner__button}>
                  <EditIcon />
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NewsTable

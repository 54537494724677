import ReactQuill from 'react-quill'

import './EditorToolbar.scss'
import 'react-quill/dist/quill.snow.css'
import EditorToolbar, { modules, formats } from './EditorToolbar'

const Editor = ({ description, updateDescription }: any) => {
  return (
    <div className='text-editor'>
      <EditorToolbar />
      <ReactQuill theme='snow' value={description} onChange={updateDescription} modules={modules} formats={formats} />
    </div>
  )
}

export default Editor

import { FC } from 'react'

import { Link, NavLink } from 'react-router-dom'

import { ModeSwitch, LangDropdown } from 'components'

import { Path } from 'libraries/router/types'
import { AmatuniPrimaryIcon, userImage } from 'assets'

import styles from './Header.module.scss'

const Header: FC = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__left}>
        <ModeSwitch />

        <LangDropdown />
      </div>

      <NavLink to={Path.SignIn} className={styles.wrapper__center}>
        <AmatuniPrimaryIcon />
        <h1 className={styles.wrapper__center__text}>AMATUNI</h1>
      </NavLink>

      <Link to={Path.MyAccount}>
        <div className={styles.wrapper__right}>
          <div>
            <img src={userImage} alt='' />
          </div>
          <p>FirstName LastName</p>
        </div>
      </Link>
    </header>
  )
}

export default Header

import { FC } from 'react'

// import { Navigate } from 'react-router-dom'

// import { Path } from 'libraries/router/types'

import type { TRoutesWrapper } from './types'

// import { useAuthentication } from 'hooks'

const RoutesWrapper: FC<TRoutesWrapper> = ({ children, isPrivate = true }) => {
  // const { isAuthenticated } = useAuthentication()

  console.log(isPrivate)

  // if (!isPrivate && isAuthenticated) {
  //   return <Navigate to={Path.Dashboard} />
  // }

  // if (isPrivate && !isAuthenticated) {
  //   return <Navigate to={Path.SignIn} />
  // }

  return children
}

export default RoutesWrapper

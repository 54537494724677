import { FC, Suspense, useMemo, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import routesList from 'libraries/router/routes'
import { gatCoins } from 'store/dashboard/actions'
import { useAppDispatch } from 'libraries/redux'
import { useAuthentication } from 'hooks'
import { HelmetLayout, RouteLoader, RoutesWrapper } from 'components'

// import AuthLayout from '../AuthLayout'

import DashboardLayout from '../DashboardLayout'

const PageLayout: FC = () => {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuthentication()

  useEffect(() => {
    dispatch(gatCoins())
  }, [])

  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title, isPrivate }) => {
        if (isPrivate) {
          return (
            <Route
              key={path}
              path={path}
              element={
                <RoutesWrapper isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
                  <HelmetLayout key={title} title={title}>
                    <DashboardLayout>
                      <Suspense fallback={<RouteLoader />}>
                        <Element />
                      </Suspense>
                    </DashboardLayout>
                  </HelmetLayout>
                </RoutesWrapper>
              }
            />
          )
        }

        return (
          <Route
            key={path}
            path={path}
            element={
              <RoutesWrapper isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
                <HelmetLayout key={title} title={title}>
                  <DashboardLayout>
                    <Suspense fallback={<RouteLoader />}>
                      <Element />
                    </Suspense>
                  </DashboardLayout>
                </HelmetLayout>
              </RoutesWrapper>
            }
          />
        )
      }),
    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}

export default PageLayout

import { TextField, TextFieldProps, styled } from '@mui/material'

export const CoinsSelectStyled = styled(TextField)<TextFieldProps>(() => ({
  width: '100%',
  color: 'white',
  borderRadius: '8px',

  '& .MuiFormLabel-root': {
    position: 'relative',
    transform: 'unset',
    color: '#8793a0',
    fontSize: '14px',
    fontWeight: ' 400',
    lineHeight: '20px',
    marginBottom: '4px',
  },

  '& .MuiSelect-select': {
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 12px 12px 16px',
    borderRadius: '8px',
    background: '#142837',
    color: '#E6F4FF',

    '& img': {
      width: '20px',
      height: '20px',
    },
  },

  '& .MuiButtonBase-root': {
    '& img': {
      width: '20px',
      height: '20px',
    },
  },
}))

import { ReactNode } from 'react'

import Header from '../Header'
import Sidebar from '../Sidebar'

import styles from './DashboardLayout.module.scss'

type Props = {
  children: ReactNode
}

const DashboardLayout = ({ children }: Props) => (
  <main className={styles.wrapper}>
    <Header />
    <section className={styles.wrapper__content}>
      <Sidebar />
      {children}
    </section>
  </main>
)

export default DashboardLayout

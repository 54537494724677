import React from 'react'
import { MenuItem, SelectChangeEvent } from '@mui/material'

import styles from './CoinsSelect.module.scss'
import { CoinsSelectStyled } from './CoinsSelect.styles'

const CoinsSelect = ({ activeCoin, coinsList, onChange }: any) => {
  const renderCoinsList = coinsList.map(
    (coin: { id: number; coinId: string; image: string | undefined; name: string }) => (
      <MenuItem key={coin.coinId} value={coin.id}>
        <img src={coin.image} alt={coin.name} />
        {coin.name}
      </MenuItem>
    )
  )

  return (
    <div className={styles.wrapper}>
      <CoinsSelectStyled
        select
        label='Category'
        variant='outlined'
        value={activeCoin}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => onChange(event as SelectChangeEvent)}
        InputLabelProps={{ shrink: false }}
      >
        {renderCoinsList}
      </CoinsSelectStyled>
    </div>
  )
}

export default CoinsSelect

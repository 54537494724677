import { Path } from 'libraries/router/types'
import { BlogIcon, NewsIcon, SettingsIcon, SupportIcon, HistoryIcon } from 'assets'

import type { TSectionItem } from './types'

export const sidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'General',
    menuItems: [
      {
        Icon: HistoryIcon,
        text: 'Dashboard',
        navigateTo: Path.Dashboard,
      },
      {
        Icon: NewsIcon,
        text: 'News',
        navigateTo: Path.News,
      },
      {
        Icon: BlogIcon,
        text: 'Blog',
        navigateTo: Path.Blog,
      },
    ],
  },
  {
    title: 'Academy',
    menuItems: [],
  },
]

export const mobileSidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'Academy',
    menuItems: [],
  },
  {
    title: 'Other',
    menuItems: [
      {
        Icon: SettingsIcon,
        text: 'Settings',
        navigateTo: null,
      },
      {
        Icon: SupportIcon,
        text: 'Support',
        navigateTo: null,
      },
    ],
  },
]

import { createSlice } from '@reduxjs/toolkit'

import { COOKIE_EXPIRATION_TIME } from 'utils'
import { getCookie, setCookie } from 'libraries/cookie'

import { signIn, forgotPassword, resetPassword } from './actions'

import { IAuthInitialState } from './types'

export const initialState: IAuthInitialState = {
  signIn: {
    data: null,
    error: null,
    loading: false,
  },
  forgotPassword: {
    loading: false,
    error: null,
    data: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
  currentAuth: (getCookie('current_auth') as string) || null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentAuth: (state, action) => {
      state.currentAuth = action.payload
      setCookie('current_auth', action.payload, COOKIE_EXPIRATION_TIME)
    },
  },
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => {
      state.signIn.loading = true
      state.signIn.error = null
    })

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.signIn.loading = false
      state.signIn.error = null
      state.signIn.data = payload
    })

    builder.addCase(signIn.rejected, (state, action) => {
      state.signIn.loading = false
      state.signIn.error = action.payload as null
    })

    builder.addCase(forgotPassword.pending, state => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = true
    })

    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = false
      state.forgotPassword.data = action.payload
    })

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.error = action.payload as null
      state.forgotPassword.loading = false
    })

    builder.addCase(resetPassword.pending, state => {
      state.resetPassword.loading = true
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.fulfilled, state => {
      state.resetPassword.loading = false
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPassword.loading = false
      state.resetPassword.error = action.payload as null
    })
  },
})

const authReducer = authSlice.reducer

export default authReducer
